import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';

import { DataService } from 'src/app/core/services/data.service';
import { ModalComponent } from '../modal/modal.component';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { ExcelService } from 'src/app/core/services/excel.service';
import { TimeoutError } from 'rxjs';

import { Tooltip } from '../../tooltip.enum';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

export interface EventLogAlarm {
  type: string;
  date: string;
  information: string;
}

@Component({
  selector: 'app-event-log-alarm',
  templateUrl: './event-log-alarm.component.html',
  styleUrls: ['./event-log-alarm.component.scss'],
  providers: [DatePipe],
})
export class EventLogAlarmComponent implements OnInit {
  @Input() deviceID: string;
  eventslogsalarmsform: FormGroup;
  eventsAlarmsLogs: EventLogAlarm[] = [];
  eventsAlarmsLogsCopy: EventLogAlarm[] = [];
  eventTypes = [
    { name: 'Event', completed: true },
    { name: 'Alarm', completed: true },
    { name: 'Log', completed: true },
  ];
  tooltip = '';
  data;
  filteredListArr = [];
  invalidDateRangeMsg = '';
  noDataMsg = '';
  noDateRangeMsg = '';
  requiredDateRangeMsg = '';

  constructor(
    private spinner: NgxSpinnerService,
    public dataService: DataService,
    public dialog: MatDialog,
    private excelService: ExcelService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    const now = moment();
    const eventsAlarmsLogsToDate = now.format();
    const eventsAlarmsLogsFromDate = now.subtract(30, 'days').format();

    this.eventslogsalarmsform = new FormGroup({
      searchFrom: new FormControl(eventsAlarmsLogsFromDate, [
        Validators.required,
      ]),
      searchTo: new FormControl(eventsAlarmsLogsToDate, [Validators.required]),
    });

    // this.tooltip = Tooltip.elg;

    this.translate
      .stream([
        'INVALID_DATE_RANGE_MSG',
        'NO_DATA_MSG',
        'DATE_RANGE_REQUIRED_MSG',
        'NO_DATE_RANGE_MSG',
        'TOOLTIPS.elg',
      ])
      .subscribe((res) => {
        console.log(res);
        this.invalidDateRangeMsg = res['INVALID_DATE_RANGE_MSG'];
        this.noDataMsg = res['NO_DATA_MSG'];
        this.requiredDateRangeMsg = res['DATE_RANGE_REQUIRED_MSG'];
        this.noDateRangeMsg = res['NO_DATE_RANGE_MSG'];
        this.tooltip = res['TOOLTIPS.elg'];
      });

    this.loadData();
  }

  async loadData() {
    this.spinner.show();
    const eventAlarmLogPromise = this.getEventsAlarmsLogsData();
    const eventAlarmLogResult = await eventAlarmLogPromise;
    if (
      eventAlarmLogResult !== null &&
      !eventAlarmLogResult.hasOwnProperty('status')
    ) {
      this.processEventAlarmLogData(eventAlarmLogResult);
    } else {
      this.handleError(eventAlarmLogResult);
    }
    this.spinner.hide();
  }

  getEventsAlarmsLogsData() {
    let fromDate = this.eventslogsalarmsform.get('searchFrom').value;
    let toDate = this.eventslogsalarmsform.get('searchTo').value;

    if (this.eventslogsalarmsform.valid && fromDate !== '' && toDate !== '') {
      fromDate = new Date(fromDate);
      const momentFromDate = moment(fromDate).format('YYYY-MM-DD');

      toDate = new Date(toDate);
      const momentToDate = moment(toDate).format('YYYY-MM-DD');

      if (moment(momentToDate).isSameOrAfter(momentFromDate)) {
        fromDate.setHours(0, 0, 0, 0);
        fromDate = moment.utc(fromDate).format('X');

        toDate.setHours(23, 59, 0, 0);
        toDate = moment.utc(toDate).format('X');

        const obj = {
          from: fromDate,
          to: toDate,
          uuid: this.deviceID,
        };

        return this.dataService
          .getEventsAlarmsLogsData(obj)
          .toPromise()
          .catch((e) => e);
      } else {
        const message = this.invalidDateRangeMsg; //`Invalid date range. Please enter correct date.`;
        this.openModalPopUp(message);
        this.spinner.hide();
      }
    } else {
      const message = this.noDateRangeMsg; // `Date range is not selected.`;
      this.openModalPopUp(message);
      this.spinner.hide();
    }
  }

  // format received data as needed
  processEventAlarmLogData(objEventAlarmLog) {
    let eventsList = [];
    let alarmsList = [];
    let logsList = [];
    this.eventsAlarmsLogs = [];
    if (objEventAlarmLog !== null && objEventAlarmLog !== undefined) {
      const eventsAlarmsLogs = objEventAlarmLog;

      if (
        eventsAlarmsLogs.hasOwnProperty('events') &&
        eventsAlarmsLogs.events.length > 0
      ) {
        eventsList = eventsAlarmsLogs.events.map((obj) => {
          return {
            type: 'Event',
            date:
              obj.hasOwnProperty('timeStamp') && obj.timeStamp
                ? new Date(obj.timeStamp)
                : '-',
            information: obj.information,
          };
        });
      }

      if (
        eventsAlarmsLogs.hasOwnProperty('alarms') &&
        eventsAlarmsLogs.alarms.length > 0
      ) {
        alarmsList = eventsAlarmsLogs.alarms.map((obj) => {
          return {
            type: 'Alarm',
            date:
              obj.hasOwnProperty('timeStamp') && obj.timeStamp
                ? new Date(obj.timeStamp)
                : '-',
            information: obj.information,
          };
        });
      }

      if (
        eventsAlarmsLogs.hasOwnProperty('logs') &&
        eventsAlarmsLogs.logs.length > 0
      ) {
        logsList = eventsAlarmsLogs.logs.map((obj) => {
          return {
            type: 'Log',
            date:
              obj.hasOwnProperty('timeStamp') && obj.timeStamp
                ? new Date(obj.timeStamp)
                : '-',
            information: obj.information,
          };
        });
      }

      this.eventsAlarmsLogs = [...eventsList, ...alarmsList, ...logsList];
      this.eventsAlarmsLogsCopy = this.eventsAlarmsLogs.slice(); // keep a copy
    }
  }

  // modal pop-ups for showing warning/error messaages
  openModalPopUp(bodyText) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
      };
      this.dialog.open(ModalComponent, dialogConfig);
    }
  }

  // filter functionality
  filterEventAlarmLog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '300px';
    dialogConfig.data = {
      labels: this.eventTypes,
    };
    this.dialog
      .open(FilterDialogComponent, dialogConfig)
      .afterClosed()
      .subscribe((res) => {
        if (res && res.hasOwnProperty('eventType')) {
          const eventType = res.eventType;

          if (eventType.length > 0) {
            // set completed to true/false in eventTypes property based on selection
            this.eventTypes.forEach((item) => {
              if (eventType.includes(item.name)) {
                item.completed = true;
              } else {
                item.completed = false;
              }
            });

            this.eventsAlarmsLogs = this.eventsAlarmsLogsCopy;

            const filteredArray = [];
            eventType.map((type) => {
              this.eventsAlarmsLogs.filter((obj) => {
                if (obj.type === type) {
                  filteredArray.push(obj);
                }
              });
            });
            this.eventsAlarmsLogs = filteredArray;
          } else {
            this.eventsAlarmsLogs = [];
          }
        }
      });
  }

  // compare data between two dates and return data
  filteredList(fromDate, toDate) {
    let filteredByDateRange;
    filteredByDateRange = this.eventsAlarmsLogs.filter((obj) => {
      const d1 = new Date(obj.date);
      let m1 = '' + (d1.getMonth() + 1);
      let day = '' + d1.getDate();
      const mStr = '';
      if (m1.length < 2) {
        m1 = '0' + m1;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      const dateStr = d1.getFullYear() + '-' + m1 + '-' + day;

      return (
        moment(dateStr).isSameOrAfter(fromDate) &&
        moment(dateStr).isSameOrBefore(toDate)
      );
    });

    return filteredByDateRange;
  }

  // export to excel functionality
  exportToExcel() {
    let fromDate = this.eventslogsalarmsform.get('searchFrom').value;
    let toDate = this.eventslogsalarmsform.get('searchTo').value;
    if (fromDate && toDate) {
      fromDate = new Date(fromDate);
      fromDate = moment(fromDate).format('YYYY-MM-DD');

      toDate = new Date(toDate);
      toDate = moment(toDate).format('YYYY-MM-DD');

      if (moment(toDate).isSameOrAfter(fromDate)) {
        const excelarr = [];
        this.filteredListArr = [];
        this.filteredListArr = this.filteredList(fromDate, toDate);
        if (this.filteredListArr.length > 0) {
          this.filteredListArr.forEach((ele: any) => {
            const date = new Date(ele.date);
            const formattedDate = new DatePipe('en-US').transform(
              date,
              'yyyy-MM-dd HH:mm:ss OOOO'
            );
            excelarr.push({
              Type: ele.type,
              Date: formattedDate, // ele.date.toString(),
              Information: ele.information,
            });
          });
          this.excelService.exportAsExcelFile(excelarr, 'Events Alarms Logs');
        } else {
          const message = this.noDataMsg; // `There is no data present within the date range selected.`;
          this.openModalPopUp(message);
        }
      } else {
        const message = this.invalidDateRangeMsg; // `Invalid date range. Please enter correct date.`;
        this.openModalPopUp(message);
      }
    } else {
      const message = this.noDateRangeMsg; // `Date is required for exporting data to excel.`;
      this.openModalPopUp(message);
    }
  }

  // sort data
  sortData(sort: Sort) {
    this.data = this.eventsAlarmsLogsCopy.slice();
    if (!sort.active || sort.direction === '') {
      this.eventsAlarmsLogs = this.data;
      return;
    }

    this.eventsAlarmsLogs = this.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'type':
          return compare(a.type, b.type, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        case 'information':
          return compare(a.information, b.information, isAsc);

        default:
          return 0;
      }
    });
  }

  handleError(err) {
    if (
      err instanceof TimeoutError ||
      (err &&
        typeof err === 'string' &&
        err.toLowerCase() === 'timeout exception')
    ) {
      // this.loginErrorMsg = 'Request timed out. Please try again.';
    } else if (err.hasOwnProperty('status') && err.status === 0) {
      // this.loginErrorMsg = 'Unable to reach the server. Please try again.';
    } else if (err.hasOwnProperty('error')) {
      let errMsgKey;

      errMsgKey = Object.keys(err.error);
      // this.loginErrorMsg =
      //   errMsgKey.length > 0
      //     ? err.error[errMsgKey[0]]
      //       ? err.error[errMsgKey[0]]
      //       : 'Unable to reach the server. Please try again.'
      //     : 'Unable to reach the server. Please try again.';
    } else {
      // this.loginErrorMsg = 'Unable to reach the server. Please try again.';
    }
    // this.showspinner = false;
    // this.eventspinner.hide();
    // this.loginError = true;
  }
}

// for sorting functionality
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
