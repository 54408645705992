import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
} from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss'],
})
export class PasswordStrengthComponent implements OnInit, OnChanges {
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  @Input() public passwordToCheck: string;
  private colors = ['darkred', 'orangered', 'orange', 'yellowgreen'];
  password = '';
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    this.password = changes.passwordToCheck.currentValue;
    this.setBarColors(4, '#DDD');
    if (this.password) {
      const c = this.getColor(this.checkStrength(this.password));
      this.setBarColors(c.index, c.color);
    }
  }

  getColor(s) {
    let index = 0;
    if (s === 10) {
      index = 0;
    } else if (s === 20) {
      index = 1;
    } else if (s === 30) {
      index = 2;
    } else if (s === 40) {
      index = 3;
    } else {
      index = 4;
    }
    return {
      index: index + 1,
      color: this.colors[index],
    };
  }

  setBarColors(count, col) {
    for (let n = 0; n < count; n++) {
      this['bar' + n] = col;
    }
  }

  checkStrength(p) {
    // 1: define a property called force of type number
    let force = 0;

    // 2: define regular expressions. The regex of special characters, lowercase test, uppercase test, number test and regex test.
    const regex = /[$-/:-?{-~!"^_@`#\[\]]/g;
    const lowerLetters = /[a-z]+/.test(p);
    const upperLetters = /[A-Z]+/.test(p);
    const numbers = /[0-9]+/.test(p);
    const symbols = regex.test(p);

    // 3: an array that contains the regular expressions to be used.
    const flags = [lowerLetters, upperLetters, numbers, symbols];

    // 4:  property called passedMatches of type number is defined and its value is incremented inside the loop depending on the regular expression that is satisfied. So, if the first criteria in the flags array is met, the value of passedMatches is incremented by 1. If the second criteria is met, the current value is also incremented by 1 until all criterias are met. Otherwise, the value 0 is added.
    let passedMatches = 0;
    for (const flag of flags) {
      passedMatches += flag === true ? 1 : 0;
    }

    // 5: checks if the password length is greater than 15 and then multiplies the passedMatches value by 15.
    force += 2 * p.length + (p.length >= 15 ? 1 : 0);
    force += passedMatches * 15;

    // 6: the length of the password (which needs to be at least 8 characters) is checked.
    force = p.length <= 8 ? Math.min(force, 10) : force;

    // 7: the final force value is set based on the value of passedMatches. Then the force value is returned.
    force = passedMatches === 1 ? Math.min(force, 10) : force;
    force = passedMatches === 2 ? Math.min(force, 20) : force;
    force = passedMatches === 3 ? Math.min(force, 30) : force;
    force = passedMatches === 4 ? Math.min(force, 40) : force;

    return force;
  }
}
