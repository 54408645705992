import { Injectable } from '@angular/core';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UtilService } from './util.service';
import { timeout, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public envName = environment.environmentName;
  // did: string;

  constructor(private http: HttpClient, private utilService: UtilService) {}

  /**
   * get UUID API
   * @param data : object
   */
  // getUUID(data, token): Observable<any> {
  //   if (this.envName === 'static') {
  //     const url = environment.uuidDetailsURL;
  //     return this.http.get(url);
  //   } else {
  //     const baseURL = environment.baseURL;
  //     const url = `${baseURL}${environment.uuidDetailsURL}`;
  //     const authToken = token.toString();
  //     const httpOptions1 = {
  //       headers: new HttpHeaders({
  //         'Content-Type': 'application/json',
  //         /* prettier-ignore */
  //         Authorization: authToken,
  //       }),
  //     };
  //     return this.http.post(url, data, httpOptions1);
  //   }
  // }
  /**
   * search results API
   * @param data : search param and value object
   */
  searchResult(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.searchDetailsURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const uuid = this.utilService.getUsername();
      const url = `${baseURL}/c/${uuid}/r/device/search?${data.key}=${data.value}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions1 = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          /* prettier-ignore */
          Authorization: authToken,
        }),
      };

      return this.http.get(url, httpOptions1);
    }
  }
  /**
   * events, logs, alarms API
   * @param data : deviceid, fromdate, todate
   */
  getEventsAlarmsLogsData(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.eventsAlarmsLogsURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.eventsAlarmsLogsURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }
  /**
   * telemetry chart API
   * @param data : deviceid, fromdate, todate
   */
  getTelemetryChartData(data) {
    if (this.envName === 'static') {
      const url = environment.telemetryURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const uuid = this.utilService.getUsername();
      const url = `${baseURL}/c/${uuid}/telemetry?deviceId=${data.deviceID}&from=${data.startDate}&to=${data.endDate}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url, httpOptions);
    }
  }
  /**
   * set actuator list API
   * @param data : actuator name, value, type, deviceid
   */
  setActuatorInfo(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.actuatorInfoURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}/c/${data.did}/a/${data.name}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      const obj = { n: data.name };
      if (data.type === 'boolean') {
        /* tslint:disable */
        obj['vb'] = data.value;
      } else {
        /* tslint:disable */
        obj['v'] = data.value;
      }
      return this.http.post(url, obj, httpOptions);
    }
  }
  /**
   * get actuator list API
   * @param data : deviceid
   */
  getActuatorsList(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.getActuatorURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.getActuatorURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }
  /**
   * device details API
   * @param data : deviceid
   */
  getDeviceDetails(data) {
    if (this.envName === 'static') {
      const url = environment.deviceDetailsURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const uuid = this.utilService.getUsername();
      const url = `${baseURL}/c/${uuid}/r/device/info?uuid=${data.deviceId}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url, httpOptions);
    }
  }
  /**
   *
   *
   */
  connectAWS(): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.connectAWSURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.connectAWSURL}`;
      // let authToken = localStorage.getItem('AuthToken').toString();
      const authToken = this.utilService.getAccessToken();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };

      return this.http.post(url, {}, httpOptions);
    }
  }

  /**
   * OTA history API
   * @param data : deviceid
   */
  getOTAHistoryData(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.otaHistoryURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.otaHistoryURL}?uuid=${data.deviceId}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url, httpOptions);
    }
  }

  /**
   * get users API
   *
   */
  getUsers(): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.searchDetailsURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.getUsersURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions1 = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          /* prettier-ignore */
          Authorization: authToken,
        }),
      };

      return this.http.get(url, httpOptions1);
    }
  }

  manageUser(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.manageUserURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.manageUserURL}`;
      const authToken = this.utilService.getAccessToken();

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
        observe: 'response',
      };
      return this.http.post<any>(url, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
        observe: 'response', // this param allows us to get status code
      });
    }
  }

  /**
   * get user scope API
   * @param uuid
   */
  getUserScope(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.getUserScopeURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}/c/${data.uuid}/scope`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  // Blog-related APIs
  /**
   * get blog API
   *  @param object
   */
  getBlogs(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.blogURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.blogURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }
  /**
   * CRUD blog API
   * @param object
   */
  blog(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.blogURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.blogURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  /**
   * CRUD blog API
   * @param object
   */
  getAllBlogs(): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.blogURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.blogURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url, httpOptions);
    }
  }

  /**
   * Repair history API
   * @param data : deviceid
   */
  getRepairHistoryData(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.repairHistoryURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.repairHistoryURL}?deviceSN=${data.deviceSN}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url, httpOptions);
    }
  }

  // SMT-CM
  uploadSchema(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.uploadSchemaURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.uploadSchemaURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'text/html; charset=UTF-8',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  updataSchema(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.updateSchemaURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.updateSchemaURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  fetchSKUList(): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.fetchSKUListURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.fetchSKUListURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url, httpOptions);
    }
  }

  fetchSchema(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.fetchSchemaURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.fetchSchemaURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  otaUpgrade(data): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.fetchSchemaURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.otaUpgradeURL}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  getOtaStatus(deviceid): Observable<any> {
    if (this.envName === 'static') {
      const url = environment.fetchSKUListURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.otaStatusURL}?deviceid=${deviceid}`;
      const authToken = this.utilService.getAccessToken();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: authToken,
        }),
      };
      return this.http.get(url,httpOptions);
    }
  }

}
