import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TimeoutError } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AWSIoTData from 'aws-iot-device-sdk';
import * as AWS from 'aws-sdk';

import { environment } from '../../../environments/environment';
import { DataService } from '../../core/services/data.service';
import { UtilService } from '../../core/services/util.service';
import { ModalComponent } from '../modal/modal.component';
import { OtaHistoryComponent } from '../ota-history/ota-history.component';
import { TelemetryChartComponent } from '../telemetry-chart/telemetry-chart.component';
import { EventLogAlarmComponent } from '../event-log-alarm/event-log-alarm.component';

import { Tooltip } from '../../tooltip.enum';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

/* tslint:disable */
var mqttClient;
/* tslint:disable */
var meScope: any;

@Component({
  selector: 'app-debugger',
  templateUrl: './debugger.component.html',
  styleUrls: ['./debugger.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DebuggerComponent implements OnInit, OnDestroy {
  @ViewChild(OtaHistoryComponent, { static: false })
  otaHistory: OtaHistoryComponent;
  @ViewChild(EventLogAlarmComponent, { static: false })
  eventLogAlarm: EventLogAlarmComponent;
  @ViewChild(TelemetryChartComponent, { static: false })
  telemetry: TelemetryChartComponent;

  public actuatorForm: FormGroup;
  public debugForm: FormGroup;
  deviceID;
  public fromDate: Date;
  public toDate: Date;
  deviceObj;
  errorMessage = '';
  actuatordData;
  selectedActuator;
  successMsg;
  public showSpinner = false;
  actuatorList = [];
  // verbosity will be static only
  public verbosity = [
    { name: 'Verbose' },
    { name: 'Debug' },
    { name: 'Debug Information' },
    { name: 'Warning' },
    { name: 'Critical' },
  ];
  debugTTLValue: any;
  private env = environment;
  selectedVerbosity: any;
  public actuatorBoolValues = [{ name: 'ON' }, { name: 'OFF' }];
  desiredActuatorList = [];
  data = [];
  errorMsg: string;
  showError: boolean;
  productDetails: any;
  customerDetails: any;
  deviceDetails: any;
  actuatorDetails = [];
  sensorDetails: any;
  packagesCounter;
  lastUpdatedDesiredActuatorDetails: any;
  lastUpdatedReportedActuatorDetails: any;
  detailsTooltip = '';
  actuationTooltip = '';
  actuatorSuccessMsg = '';
  actuatorFailureMsg = '';
  actuatorTooltips;

  constructor(
    public dataService: DataService,
    private utilService: UtilService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {
    meScope = this;
      this.translate.stream("DEBUGGER.VERBOSITY").subscribe((res) => {
        //console.log(res);
        this.verbosity =res;
       });
  }

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    this.deviceID = this.utilService.getDeviceID(); // this is device uuid

    // get device and customer information
    this.productDetails = this.utilService.getDeviceData();

    // actuator form
    this.actuatorForm = new FormGroup({
      actuator: new FormControl('Fanspeed', []),
      actuatorBoolValue: new FormControl('ON', [Validators.required]),
      actuatorValue: new FormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.maxLength(3),
      ]),
    });
    // END - actuator form

    this.debugForm = new FormGroup({
      debugTTLValue: new FormControl('3600', [
        Validators.required,
        Validators.min(1),
        Validators.max(3600),
        Validators.maxLength(4),
      ]),
      verbosity: new FormControl('Verbose', []),
    });
    this.selectedVerbosity = this.debugForm.get('verbosity').value;

    meScope = this; // this is done because this scope is not available in mqtt events/methods

    // this.detailsTooltip = Tooltip.details;
    // this.actuationTooltip = Tooltip.actuation;

    this.fetchAllData();

    this.translate
      .stream([
        'DEBUGGER.SUCCESS_ACTUATOR_MSG',
        'DEBUGGER.FAILED_ACTUATOR_MSG',
        'TOOLTIPS.details',
        'TOOLTIPS.actuation',
        'TOOLTIPS.ACTUATORS',
      ])
      .subscribe((res) => {
        // console.log(res);
        this.actuatorSuccessMsg = res['DEBUGGER.SUCCESS_ACTUATOR_MSG'];
        this.actuatorFailureMsg = res['DEBUGGER.FAILED_ACTUATOR_MSG'];
        this.detailsTooltip = res['TOOLTIPS.details'];
        this.actuationTooltip = res['TOOLTIPS.actuation'];
        this.actuatorTooltips = res['TOOLTIPS.ACTUATORS'];
      });
  }

  async fetchAllData() {
    this.spinner.show();
    const deviceDataPromise = this.getDeviceDetails();
    const actuatorDataPromise = this.getActuatorsList();

    // const deviceDataResult = await deviceDataPromise;
    // const actuatorDataResult = await actuatorDataPromise;

    let values = await Promise.all([deviceDataPromise, actuatorDataPromise]);
    if (values && values.length > 0) {
      this.processDeviceData(values[0]);
      this.processActuatorData(values[1]);
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }

    // close spinner only after all child components data is loaded
    // if (
    //   deviceDataResult !== null &&
    //   actuatorDataResult !== null &&
    //   this.otaHistory.otaHistoryData !== null &&
    //   this.eventLogAlarm.eventsAlarmsLogs !== null &&
    //   this.telemetry.telemtryChartData !== null
    // ) {
    //   this.spinner.hide();
    // }

    // if (deviceDataResult !== null) {
    //   this.processDeviceData(deviceDataResult);
    // }

    // if (actuatorDataResult !== null) {
    //   this.processActuatorData(actuatorDataResult);
    // }
    // this.spinner.hide();
  }

  // fetch device and customer info
  getDeviceDetails() {
    const obj = { deviceId: this.deviceID };
    return this.dataService
      .getDeviceDetails(obj)
      .toPromise()
      .catch((e) => e);
  }

  // format received data as needed
  processDeviceData(objDeviceData) {
    this.customerDetails = {};
    this.deviceDetails = {};
    this.actuatorDetails = [];
    this.sensorDetails = [];

    if (
      objDeviceData.hasOwnProperty('customerinfo') &&
      objDeviceData['customerinfo'] !== null
    ) {
      this.customerDetails = objDeviceData['customerinfo'];
    }

    if (
      objDeviceData.hasOwnProperty('devicedetails') &&
      objDeviceData['devicedetails'] !== null
    ) {
      this.deviceDetails = objDeviceData['devicedetails'];
    }

    if (
      objDeviceData.hasOwnProperty('actuatorsinfo') &&
      objDeviceData['actuatorsinfo'] !== null
    ) {
      // Reported Actuator List
      this.actuatorDetails = Object.entries(objDeviceData['actuatorsinfo'])
        .map((obj) => {
          return {
            name: obj[0],
            value: obj[1],
          };
        })
        .filter((obj) => {
          if (obj.name === 'timestamp') {
            this.lastUpdatedReportedActuatorDetails = obj.value;
          } else {
            /**** All this processing is done at the UI instead of the back-end. This is not best practices but for now this will have to do ****/
            // split names found in the array
            const namesToSplit = [
              { oldVal: 'filterusage', newVal: 'filter usage' },
              { oldVal: 'fanspeed', newVal: 'fan speed' },
              { oldVal: 'safetyswitch', newVal: 'door' },
              { oldVal: 'automode', newVal: 'auto mode' },
              { oldVal: 'nightmode', newVal: 'night mode' },
            ];

            namesToSplit.forEach((o) => {
              if (o.oldVal === obj.name) {
                obj.name = o.newVal;
              }
            });

            // change true/false to on/off
            if (obj.value === 'true') {
              obj.value = 'ON';
            } else if (obj.value === 'false') {
              obj.value = 'OFF';
            }

            // have to add units as well ... more processing :( :(
            if (obj.name === 'filter usage') {
              obj.value += '% (Consumed)';
            }

            if (obj.name === 'brightness') {
              obj.value += '%';
            }

            if (obj.name === 'door') {
              obj.value = obj.value === 'ON' ? 'CLOSED' : 'OPEN';
            }

            //  add tooltip for actuators
            for (const [key, value] of Object.entries(this.actuatorTooltips)) {
              if (obj.name === key) {
                obj['tooltip'] = value;
              }
            }

            return obj;
          }
        });
    }

    if (
      objDeviceData.hasOwnProperty('sensorsinfo') &&
      objDeviceData['sensorsinfo'] !== null
    ) {
      this.sensorDetails = objDeviceData['sensorsinfo'];
    }
  }

  // get actuator list
  getActuatorsList() {
    const obj = { uuid: this.deviceID };
    return this.dataService
      .getActuatorsList(obj)
      .toPromise()
      .catch((e) => e);
  }

  // format received data as needed
  processActuatorData(objActuatorData) {
    this.actuatorList = [];
    this.desiredActuatorList = [];
    if (objActuatorData && Object.keys(objActuatorData).length > 0) {
      // desired actuator list to display in device details
      this.desiredActuatorList = Object.entries(objActuatorData)
        .map((obj) => {
          return {
            name: obj[0],
            value: obj[1],
          };
        })
        .filter((obj) => {
          if (obj.name.toLowerCase() === 'timestamp') {
            this.lastUpdatedDesiredActuatorDetails = obj.value;
          } else if (
            obj.name !== 'cfv' &&
            obj.name !== 'mfv' &&
            obj.name !== 'ofv'
          ) {
            /**** All this processing is done at the UI instead of the back-end. This is not best practices but for now this will have to do ****/
            // split names found in the array
            const namesToSplit = [
              { oldVal: 'filterusage', newVal: 'filter usage' },
              { oldVal: 'fanspeed', newVal: 'fan speed' },
              { oldVal: 'safetyswitch', newVal: 'door' },
              { oldVal: 'automode', newVal: 'auto mode' },
              { oldVal: 'nightmode', newVal: 'night mode' },
            ];

            namesToSplit.forEach((o) => {
              if (o.oldVal === obj.name) {
                obj.name = o.newVal;
              }
            });

            // change true/false to on/off
            if (obj.value === true) {
              obj.value = 'ON';
            } else if (obj.value === false) {
              obj.value = 'OFF';
            }

            // have to add units as well ... more processing :( :(
            if (obj.name === 'filter usage') {
              obj.value += '% (Consumed)';
            }

            if (obj.name === 'brightness') {
              obj.value += '%';
            }

            if (obj.name === 'door') {
              obj.value = obj.value === 'ON' ? 'CLOSED' : 'OPEN';
            }

            //  add tooltip for actuators
            for (const [key, value] of Object.entries(this.actuatorTooltips)) {
              if (obj.name === key) {
                obj['tooltip'] = value;
              }
            }

            return obj;
          }
        });
      // actuator list to display in dropdown
      this.actuatorList = Object.entries(objActuatorData)
        .map((obj) => {
          if (
            obj[0] &&
            obj[0] !== 'ofv' &&
            obj[0] !== 'mfv' &&
            obj[0] !== 'cfv' &&
            obj[0] !== 'safetyswitch' &&
            obj[0] !== 'filterusage' &&
            obj[0] !== 'welcome' &&
            obj[0] !== 'timestamp'
          ) {
            return {
              name: obj[0].toUpperCase(),
              type:
                obj[1] === 'true' ||
                obj[1] === 'false' ||
                obj[1] === true ||
                obj[1] === false
                  ? 'boolean'
                  : 'number',
              value: obj[1],
            };
          }
        })
        .filter((object) => {
          if (object) return object;
        });

      // set first item as default
      this.actuatorForm.controls['actuator'].setValue(this.actuatorList[0]);
      this.getSelectedActuator();
    }
  }

  // get selected actuator
  getSelectedActuator() {
    this.selectedActuator = this.actuatorForm.get('actuator').value;
    if (this.selectedActuator.type === 'number') {
      this.actuatorForm.controls['actuatorValue'].setValue(
        this.selectedActuator.value
      );
    } else {
      const actuatorValue =
        this.selectedActuator.value === 'false' ||
        this.selectedActuator.value === false
          ? 'OFF'
          : 'ON';
      this.actuatorForm.controls['actuatorBoolValue'].setValue(actuatorValue);
    }
  }

  // actuator input field validation
  hasError(fromName, controlName: string, errorName: string) {
    return this[fromName].controls[controlName].hasError(errorName);
  }

  async refreshActuatorData() {
    this.spinner.show();
    const actuatorList = await this.getActuatorsList();
    if (actuatorList !== null) {
      this.processActuatorData(actuatorList);
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  // called to set Actuator value
  setActuatorValue() {
    this.spinner.show();
    let actuatorValue;
    // get value based on actuator selected
    if (
      this.selectedActuator &&
      this.selectedActuator.hasOwnProperty('type') &&
      this.selectedActuator.type === 'number'
    ) {
      actuatorValue = parseInt(this.actuatorForm.get('actuatorValue').value);
    } else {
      actuatorValue =
        this.actuatorForm.get('actuatorBoolValue').value === 'ON'
          ? true
          : false;
    }

    const obj = {
      name:
        this.selectedActuator &&
        this.selectedActuator.hasOwnProperty('name') &&
        this.selectedActuator.name.toLowerCase(),
      type:
        this.selectedActuator &&
        this.selectedActuator.hasOwnProperty('type') &&
        this.selectedActuator.type,
      value: actuatorValue,
      did: this.deviceID,
    };

    // call actuator API
    this.dataService.setActuatorInfo(obj).subscribe(
      (res) => {
        let message = '';
        if (res === 'Success') {
          message = this.actuatorSuccessMsg; // 'Successfully set actuator value.';

          // fetch actuator list after setting the actuator value
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.data = {
            message,
          };
          this.dialog
            .open(ModalComponent, dialogConfig)
            .afterClosed()
            .subscribe((res1) => {
              this.refreshActuatorData();
              this.refreshDeviceInfo();
            });
          // End
        } else {
          message = this.actuatorFailureMsg; // 'Failed to set actuator value.';
          this.openModalPopUp(message);
        }
        this.spinner.hide();
        this.actuatordData = res;
      },
      (err) => {
        let message;
        if (
          err instanceof TimeoutError ||
          (err &&
            typeof err === 'string' &&
            err.toLowerCase() === 'timeout exception')
        ) {
          message = 'Request timmed out. Please try again.';
        } else if (err.hasOwnProperty('status') && err.status == 403) {
          this.handleError(err);
        } else {
          message = this.actuatorFailureMsg; //'Failed to set Actuator value.';
        }
        this.spinner.hide();

        this.openModalPopUp(message);
      }
    );
  }

  // get selected verbosity
  getSelectedVerbosity() {
    this.selectedVerbosity = this.debugForm.get('verbosity').value;
  }

  // enable/disable logs
  toggleLogs(mode) {
    if (mode === 'enable') {
      this.debugTTLValue = this.debugForm.get('debugTTLValue').value;
      if (
        this.selectedVerbosity &&
        this.debugTTLValue &&
        parseInt(this.debugTTLValue) > 0
      ) {
        this.data = [
          {
            bn: `urn:dev:ow:${this.deviceID}:devicelogs:`,
            n: 'verbosity',
            v: this.selectedVerbosity,
          },
          { n: 'ttl', v: parseInt(this.debugTTLValue) },
        ];
        // call initClient to publish and subscribe
        this.connectAWS();
      } else {
        this.spinner.hide();
      }
    } else {
      this.disableLogs();
    }
  }

  disableLogs() {
    try {
      this.spinner.show();

      const deviceTpoic = `d/${this.deviceID}/a/senml`;
      if (mqttClient) {
        mqttClient.unsubscribe([deviceTpoic], function (result) {
          meScope.openModalPopUp('Logs are disabled.');
        });
        mqttClient.end();

        mqttClient = null;
      }
      clearInterval(this.packagesCounter);
      this.spinner.hide();
    } catch (err) {}
  }

  openModalPopUp(bodyText) {
    let loginStatus = localStorage.getItem('smtLogin');
    if (loginStatus === '1') {
      if (bodyText) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          message: bodyText,
        };
        this.dialog.open(ModalComponent, dialogConfig);
      }
    }
  }

  async refreshDeviceInfo() {
    this.spinner.show();
    const deviceDataResult = await this.getDeviceDetails();
    if (deviceDataResult !== null) {
      this.processDeviceData(deviceDataResult);
      this.spinner.hide();
    } else {
      this.spinner.hide();
    }
  }

  // AWS
  /**
   *  AWS MQTT connectivity implementation START
   */
  //  get access and secret keys from AWS
  connectAWS() {
    this.spinner.show();
    this.dataService.connectAWS().subscribe(
      (res) => {
        if (res && res !== null && res !== '') {
          const str = atob(res);
          let data = str
            .substring(1, str.length - 1)
            .trim()
            .split(',');
          let obj = {};
          // pathetic coding begins here
          data.forEach(function (item) {
            let val = item.split(':');
            val[0] = val[0].trim().substring(1, val[0].length - 1);
            val[1] = val[1].trim().substring(1, val[1].length - 2);
            obj[val[0]] = val[1];
          });
          // pathetic coding ends here
          this.initClient(obj);
        } else {
          this.openModalPopUp('An unexpected error occurred.');
          this.spinner.hide();
        }
      },
      (err) => {
        if (err.hasOwnProperty('status') && err.status === 403) {
          this.handleError(err);
        } else {
          this.openModalPopUp('An unexpected error occurred.');
        }
        this.spinner.hide();
      }
    );
  }

  initClient(obj) {
    try {
      //var clientId = String(Math.random()).replace('.', '');
      mqttClient = AWSIoTData.device({
        region: this.env.region,
        host: this.env.host,
        clientId: '', //clientId,
        protocol: 'wss',
        maximumReconnectTimeMs: 8000,
        debug: false,
        accessKeyId: obj['aws_access_key_id'],
        secretKey: obj["aws_secret_access_key'"],
      });
      // console.log(mqttClient);

      mqttClient.on('connect', this.mqttClientConnectHandler);
      mqttClient.on('message', this.mqttClientMessageHandler);
      mqttClient.on('error', function (err) {
        meScope.spinner.hide();
        mqttClient.end();
        meScope.openModalPopUp('Error in connecting to MQTT client');
      });
    } catch (err) {
      meScope.openModalPopUp('An unexpected error occurred.');
      meScope.spinner.hide();
    }
  }

  // to publish data
  mqttClientConnectHandler() {
    try {
      if (mqttClient && mqttClient.hasOwnProperty('publish')) {
        const deviceTopic = `d/${meScope.deviceID}/a/senml`;
        mqttClient.publish(deviceTopic, JSON.stringify(meScope['data']));
        const msg = 'Logs enabled successfully';
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          message: msg,
        };
        meScope.dialog
          .open(ModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((res1) => {
            // after the said time the logs should stop coming;
            let timeLeft = parseInt(meScope.debugTTLValue);
            meScope.packagesCounter = setInterval(() => {
              if (timeLeft > 0) {
                timeLeft--;
              } else if (timeLeft === 0) {
                meScope.disableLogs();
              }
            }, 1000);
          });
      }

      // subscribe

      mqttClient.subscribe(`d/${meScope.deviceID}/devicelogs`);
      meScope.spinner.hide();
    } catch (err) {
      meScope.spinner.hide();
    }
  }

  // to subscribe/get logs
  mqttClientMessageHandler(topic, payload, packet) {
    const deviceTopic = `d/${meScope.deviceID}/devicelogs`;
    if (topic === deviceTopic) {
      const message = payload ? JSON.parse(payload) : '';
      // console.log(message);

      let iotMsg = '',
        messageType = '';
      if (message && message.hasOwnProperty('ot') && message.ot) {
        messageType = message.ot;
        switch (message.ot) {
          case 'Alarm':
            iotMsg = message['alarm-text'];
            break;
          case 'Event':
            iotMsg = message['m'];
            break;
        }
      }

      const information = iotMsg
        ? iotMsg
        : message.hasOwnProperty('m') && message.m
        ? message.m
        : '';

      // console.log({ information });

      const date = message.hasOwnProperty('ts')
        ? new Date(message.ts * 1000).toLocaleString()
        : new Date().toLocaleString();

      // console.log({ date });
      // console.log(message);
      if (message && message !== '') {
        const debugLogArr = meScope.eventLogAlarm.eventsAlarmsLogs.filter(
          (obj) => {
            if (obj.hasOwnProperty('debugLogs') && obj.debugLogs) {
              return obj;
            }
          }
        );
        // console.log(debugLogArr);
        let isDuplicateMsg = false;
        if (debugLogArr.length > 0) {
          // let's try to do this via date/timestamp instead of message
          for (let i = 0; i < debugLogArr.length; i++) {
            // console.log(date);
            // console.log(debugLogArr[i].date);
            // console.log(moment(date).isSame(debugLogArr[i].date));
            if (
              // information.trim().toLowerCase() ===
              // debugLogArr[i].information.trim().toLowerCase()
              moment(date).isSame(debugLogArr[i].date)
            ) {
              isDuplicateMsg = true;
              break;
            }
          }

          // console.log(isDuplicateMsg);

          if (!isDuplicateMsg) {
            meScope.eventLogAlarm.eventsAlarmsLogs.unshift({
              type: messageType,
              date,
              information,
              debugLogs: true,
            });
          }
        } else {
          meScope.eventLogAlarm.eventsAlarmsLogs.unshift({
            type: messageType,
            date,
            information,
            debugLogs: true,
          });
        }

        // console.log(meScope.eventLogAlarm.eventsAlarmsLogs);

        // meScope.cdRef.detectChanges();
      }
    }
  }

  // END - AWS

  handleError(obj) {
    this.utilService.onUnauthorized(obj);
  }

  ngOnDestroy() {
    const deviceTpoic = `d/${this.deviceID}/a/senml`;
    if (mqttClient) {
      mqttClient.unsubscribe([deviceTpoic], function (result) {
        // meScope.openModalPopUp('Logs are disabled.');
      });
      mqttClient.end();

      mqttClient = null;
    }
    clearInterval(this.packagesCounter);
  }
}
