import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DataService } from '../../core/services/data.service';
import { UtilService } from '../../core/services/util.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimeoutError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-details',
  templateUrl: './search-details.component.html',
  styleUrls: ['./search-details.component.scss'],
})
export class SearchDetailsComponent implements OnInit, OnChanges {
  @Input() searchParam;
  @Input() searchfield: string;
  searchText = '';
  deviceID = '';
  objSearch;
  searchResults = [];
  errorMsg: string;
  showspinner: boolean;
  isError: boolean;
  userScope;
  requestTimedOutMsg = '';
  unableToReachServerMsg = '';
  noDataToDisplayMsg = '';
  sessionExpiryMsg = '';

  constructor(
    private router: Router,
    public dataService: DataService,
    private utilService: UtilService,
    private spinner: NgxSpinnerService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.userScope = this.utilService.getUserScope();

    this.translate
      .stream([
        'REQUEST_TIMED_OUT',
        'UNABLE_TO_REACH_SERVER',
        'SEARCH_DETAIL.NO_DATA_TO_DISPLAY',
        'SESSION_EXPIRY_MSG',
      ])
      .subscribe((res) => {
        console.log(res);
        this.requestTimedOutMsg = res['REQUEST_TIMED_OUT'];
        this.unableToReachServerMsg = res['UNABLE_TO_REACH_SERVER'];
        this.noDataToDisplayMsg = res['SEARCH_DETAIL.NO_DATA_TO_DISPLAY'];
        this.sessionExpiryMsg = res['SESSION_EXPIRY_MSG'];
      });
  }

  ngOnChanges() {
    this.searchText = decodeURIComponent(this.searchParam.value);
    this.searchdetailsData(this.searchParam);
  }

  async searchdetailsData(searchObj) {
    this.searchResults = [];
    this.isError = false;
    this.errorMsg = '';

    this.spinner.show();

    const searchResult = await this.dataService
      .searchResult(searchObj)
      .toPromise()
      .catch((e) => e);

    if (searchResult && searchResult.length > 0) {
      this.searchResults = searchResult;
      this.spinner.hide();
    } else {
      this.handleError(searchResult);
    }
  }

  handleError(err) {
    if (
      err instanceof TimeoutError ||
      (err &&
        typeof err === 'string' &&
        err.toLowerCase() === 'timeout exception')
    ) {
      this.errorMsg = this.requestTimedOutMsg; // 'Request timed out. Please try again.';
    } else if (err.hasOwnProperty('status') && err.status === 0) {
      this.errorMsg = this.unableToReachServerMsg; // 'Unable to reach the server. Please try again.';
    } else if (err.hasOwnProperty('status') && err.status === 403) {
      this.utilService.onUnauthorized(err);
    } else if (err.hasOwnProperty('error')) {
      this.errorMsg = this.sessionExpiryMsg; // 'Session has expired. Please login again.';
      this.router.navigate(['/login']);
    } else {
      let searchValue = this.searchParam.value;
      if (this.searchParam.key === 'email') {
        searchValue = decodeURIComponent(this.searchParam.value);
      }
      this.errorMsg = `${this.noDataToDisplayMsg} ${this.searchParam.key}:${searchValue}`;
    }

    this.spinner.hide();
    this.isError = true;
  }

  goToDeviceInfo(deviceDetails) {
    this.utilService.setDeviceID(deviceDetails.uuid);
    this.utilService.setDeviceData(deviceDetails);
    this.router.navigate(['/device-info']);
  }

  goToDebugger(deviceDetails) {
    this.utilService.setDeviceID(deviceDetails.uuid);
    this.utilService.setDeviceData(deviceDetails);
    this.router.navigate(['/debugger']);
  }
}
