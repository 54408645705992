import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

@NgModule({
  imports:
      [MatButtonModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatTabsModule,
      MatToolbarModule,
      MatCardModule,
      MatTableModule,
      MatProgressSpinnerModule,
      MatSelectModule,
      MatGridListModule,
      MatCheckboxModule,
      MatProgressBarModule
],
  exports:
      [MatButtonModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatTabsModule,
      MatToolbarModule,
      MatCardModule,
      MatTableModule,
      MatProgressSpinnerModule,
      MatSelectModule,
      MatGridListModule,
      MatCheckboxModule,
      MatProgressBarModule
  ]
})
// @NgModule({
//   declarations: [],
//   imports: [
//     CommonModule
//   ]
// })
export class SharedModule { }
