import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  isPreTag = false;
  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    // console.log(this.modalData);
  }

  ngOnInit() {
    this.isPreTag = this.modalData.isPreTag;
    // console.log(this.isPreTag);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
