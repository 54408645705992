import { Component, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material';
import { DataService } from 'src/app/core/services/data.service';
import { ExcelService } from 'src/app/core/services/excel.service';
import { DatePipe } from '@angular/common';

export interface RepairHistory {
  brokenpart: {
    sku: string;
    revision: string;
    serial: string;
    macId?: string;
  };
  sc: {
    id: string;
  };
  deviceSN: string;
  replacementpart: {
    sku: string;
    revision: string;
    serial: string;
    macId?: string;
  };
  ts: number;
}

@Component({
  selector: 'app-repair-info',
  templateUrl: './repair-info.component.html',
  styleUrls: ['./repair-info.component.scss'],
  providers: [DatePipe],
})
export class RepairInfoComponent implements OnInit {
  @Input() deviceSN: string;
  repairHistoryData: RepairHistory[] = [];
  repairHistoryDataCopy: RepairHistory[] = [];
  data;
  repairHistoryResult: any[];

  constructor(
    public dataService: DataService,
    public excelService: ExcelService
  ) {}

  ngOnInit() {
    if (this.deviceSN) {
      this.fetchData();
    }
  }

  async fetchData() {
    const repairHistoryPromise = this.getRepairHistoryData();
    let repairHistoryResult = await repairHistoryPromise;
    const num = 1000; // multiply timestamp by 1000 for date parsing
    if (repairHistoryResult && repairHistoryResult.length > 0) {
      // sort in descending order
      repairHistoryResult = repairHistoryResult.sort(
        (a, b) => b.ts * num - a.ts * num
      );

      this.repairHistoryData = repairHistoryResult.slice();
      this.repairHistoryDataCopy = repairHistoryResult.slice();
    }
  }

  getRepairHistoryData() {
    const obj = {
      deviceSN: this.deviceSN,
    };
    return this.dataService
      .getRepairHistoryData(obj)
      .toPromise()
      .catch((e) => e);
  }

  // sort data
  sortData(sort: Sort) {
    this.data = this.repairHistoryDataCopy.slice();
    if (!sort.active || sort.direction === '') {
      this.repairHistoryData = this.data;
      return;
    }

    this.repairHistoryData = this.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sku_broken':
          return compare(a.sku_broken, b.sku_broken, isAsc);
        case 'revision_broken_part':
          return compare(a.revision_broken_part, b.revision_broken_part, isAsc);
        case 'sn_broken_part':
          return compare(a.sn_broken_part, b.sn_broken_part, isAsc);
        case 'sku_replacement_part':
          return compare(a.sku_replacement_part, b.sku_replacement_part, isAsc);
        case 'revision_replacement_part':
          return compare(
            a.revision_replacement_part,
            b.revision_replacement_part,
            isAsc
          );
        case 'sn_replacement_part':
          return compare(a.sn_replacement_part, b.sn_replacement_part, isAsc);
        case 'timestamp':
          return compare(a.timestamp, b.timestamp, isAsc);

        default:
          return 0;
      }
    });
  }

  // export to excel functionality
  exportToExcel() {
    const excelarr = [];

    if (this.repairHistoryData.length > 0) {
      this.repairHistoryData.forEach((ele: any) => {
        const date = new Date(ele.ts * 1000);
        const formattedDate = new DatePipe('en-US').transform(
          date,
          'yyyy-MM-dd HH:mm:ss OOOO'
        );
        excelarr.push({
          'SKU Broken Part': ele.brokenpart.sku,
          'Revision Broken Part': ele.brokenpart.revision,
          'SN Broken Part': ele.brokenpart.serial,
          'SKU Replacement Part': ele.replacementpart.sku,
          'Revision Replacement Part': ele.replacementpart.revision,
          'SN Replacement Part': ele.replacementpart.serial,
          'Date (MM/DD/YYY)': formattedDate,
        });
      });
      this.excelService.exportAsExcelFile(excelarr, 'Repair History');
    }
  }
}

// for sorting functionality
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
