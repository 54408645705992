import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-blog',
  templateUrl: './modal-blog.component.html',
  styleUrls: ['./modal-blog.component.scss'],
})
export class ModalBlogComponent implements OnInit {
  blogDescription = '';
  constructor(
    public dialogRef: MatDialogRef<ModalBlogComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {
    this.blogDescription =
      this.modalData &&
      this.modalData.hasOwnProperty('blog') &&
      this.modalData.blog &&
      this.modalData.blog.hasOwnProperty('description') &&
      this.modalData.blog.description
        ? this.modalData.blog.description
        : '';
  }

  closeModal() {
    this.dialogRef.close();
  }
}
