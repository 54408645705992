import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalComponent } from '../../smt/modal/modal.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  appUUID = '';
  deviceID = '';
  usernameUUID = '';
  productData: any;
  accessToken = '';
  helper = new JwtHelperService();
  temperature: any;
  userScope: any;
  loginStatus = '';
  token = '';
  telemetryData;
  sessionExpiryMsg = '';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.translate.stream('SESSION_EXPIRY_MSG').subscribe((res) => {
      console.log(res);
      this.sessionExpiryMsg = res;
    });
  }
  // getters and setters
  setDeviceID(deviceID) {
    this.deviceID = deviceID;
    sessionStorage.setItem('deviceID', this.deviceID);
  }
  getDeviceID() {
    return this.deviceID || sessionStorage.getItem('deviceID');
  }

  setUsername(username) {
    this.usernameUUID = username;
    sessionStorage.setItem('username', this.usernameUUID);
  }
  getUsername() {
    return this.usernameUUID || sessionStorage.getItem('username');
  }

  setDeviceData(deviceData) {
    this.productData = deviceData;
    sessionStorage.setItem('productData', JSON.stringify(this.productData));
  }
  getDeviceData() {
    return this.productData || sessionStorage.getItem('productData')
      ? JSON.parse(sessionStorage.getItem('productData'))
      : '';
  }

  setAccessToken(token) {
    this.accessToken = token;
    sessionStorage.setItem('access_token', this.accessToken);
  }
  getAccessToken() {
    return this.accessToken || sessionStorage.getItem('access_token');
  }

  setUserScope(scope) {
    this.userScope = scope;
    sessionStorage.setItem('userScope', this.userScope);
  }
  getUserScope() {
    return this.userScope || sessionStorage.getItem('userScope');
  }

  setTemp(temp) {
    this.temperature = temp;
    localStorage.setItem('temp', temp);
  }
  getTemp() {
    return localStorage.getItem('temp');
  }

  setTelemetryData(objSensorData) {
    this.telemetryData = objSensorData;
    sessionStorage.setItem('telemetryData', JSON.stringify(this.telemetryData));
  }
  getTelemetryData() {
    return this.telemetryData || sessionStorage.getItem('telemetryData');
  }
  clearTelemetryData() {
    this.telemetryData = null;
    sessionStorage.setItem('telemetryData', '');
  }

  /**
   * Admin logout will do logout for user
   */
  adminLogout() {
    localStorage.setItem('smtLogin', '0');
    localStorage.setItem('temp', 'celsius');
    sessionStorage.setItem('userScope', '');
    sessionStorage.setItem('username', '');
    sessionStorage.setItem('access_token', '');
    localStorage.setItem('passwordDisplayMsg', '');
    sessionStorage.setItem('deviceID', '');
    sessionStorage.setItem('productData', '');
    sessionStorage.setItem('telemetryData', '');
    this.router.navigate(['/login']);
  }

  onUnauthorized(obj) {
    let msg;
    let isLogout = false;
    if (
      obj &&
      obj.hasOwnProperty('error') &&
      obj.error &&
      obj.error.hasOwnProperty('Message') &&
      obj.error.Message
    ) {
      msg = obj.error.Message;
      isLogout = false;
    } else {
      msg = this.sessionExpiryMsg; // 'Session expired. Please login again.';
      isLogout = true;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      message: msg,
    };
    this.dialog
      .open(ModalComponent, dialogConfig)
      .afterClosed()
      .subscribe((res1) => {
        if (isLogout) {
          this.adminLogout();
        }
      });
    // End
  }

  checkIfLoggedIn() {
    this.loginStatus = localStorage.getItem('smtLogin');
    if (this.loginStatus !== '1') {
      this.adminLogout();
    }
  }

  // validate authentication token
  validateAuthToken() {
    this.token = this.accessToken;
    if (this.token && this.token !== '' && this.token !== null) {
      return this.helper.isTokenExpired(this.token); // token expired then return true
    }
  }
}
