import { CommentStmt, ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/core/services/data.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-create-configuration-manager',
  templateUrl: './create-configuration-manager.component.html',
  styleUrls: ['./create-configuration-manager.component.scss'],
})
export class CreateConfigurationManagerComponent implements OnInit {
  yamlContent = '';
  isDisabled = true;
  schemaValidationMsg = '';
  schemaUploadMsg = '';

  constructor(
    private router: Router,
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    public utilService: UtilService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.utilService.checkIfLoggedIn();

    this.translate
      .stream([
        'CONFIG_MANAGER.SCHEMA_VALIDATION_SUCCESS',
        'CONFIG_MANAGER.CREATE.SCHEMA_UPLOAD_SUCCESS',
      ])
      .subscribe((res) => {
        console.log(res);
        this.schemaValidationMsg =
          res['CONFIG_MANAGER.SCHEMA_VALIDATION_SUCCESS'];
        this.schemaUploadMsg =
          res['CONFIG_MANAGER.CREATE.SCHEMA_UPLOAD_SUCCESS'];
      });
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  // validation for json/yaml content
  validate() {
    this.spinner.show();

    if (this.yamlContent.trim() !== '') {
      this.isDisabled = false;
      this.openModalPopUp(this.schemaValidationMsg, '');
    } else {
      this.isDisabled = true;
      this.openModalPopUp('Invalid schema.', '');
    }

    this.spinner.hide();
  }

  upload() {
    this.spinner.show();
    this.dataService.uploadSchema(this.yamlContent).subscribe(
      (res) => {
        // console.log(res);
        let message = '',
          mode = '';
        // if (res && res.hasOwnProperty('body')) {
        // const response =
        //   res.body && this.isJsonString(res.body) ? JSON.parse(res.body) : '';

        if (
          res !== '' &&
          res !== null &&
          res.hasOwnProperty('statusmessage') &&
          res.statusmessage === 'success'
        ) {
          message = this.schemaUploadMsg; // 'Schema uploaded successfully.';
          mode = 'redirect';
        } else {
          message = 'There was an error while uploading schema.';
          mode = '';
        }
        // } else {
        //   message = 'There was an error while uploading schema.';
        //   mode = '';
        // }

        this.spinner.hide();

        this.openModalPopUp(message, mode);
      },
      (err) => {
        if (err && err.hasOwnProperty('error') && err.error) {
          // API response is giving a string which needs JSON.stringified and then JSON.parsed
          const errorMsg = JSON.parse(JSON.stringify(err.error));
          const message =
            errorMsg['Message'] || 'There was an error while uploading schema.';
          this.openModalPopUp(message, '');
        }

        this.spinner.hide();
      }
    );
  }

  goToConfigManager() {
    this.router.navigate(['/configuration-manager']);
  }

  // modal pop-ups for showing warning/error messaages
  openModalPopUp(bodyText, mode) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
      };

      if (mode === 'redirect') {
        this.dialog
          .open(ModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((res1) => {
            // this.goToConfigManager();
          });
      } else {
        this.dialog.open(ModalComponent, dialogConfig);
      }
    }
  }
}
