// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  environmentName: '',
  baseURL: '',
  region: '',
  host: '',
  regionName: '',

  loginClientId: '2m1pfaga6vdd557fnl7lbn1ljq',
  loginClientSecret: '1r152pm64c1ojfdi9acnqpjlop0kl8kocl3kij3qj988p6cem4t8',
  loginScope: 'sm',
  loginGrantType: 'client_credentials',
  loginAudience: 'ToDo',
  loginURL: '/c/authenticate',
  uuidDetailsURL: '/c/register',
  deviceMonitorURL: 'https://console.amazonaws.cn/console/home',
  searchDetailsURL: '',
  eventsAlarmsLogsURL: '/c/smt',
  telemetryURL: '',
  actuatorInfoURL: '',
  getActuatorURL: '/c/smt/actuator-list',
  deviceDetailsURL: '',
  identityPoolId: '',
  userPoolId: '',
  clientId: '',
  policyName: '',
  connectAWSURL: '/c/smt/get-secrect',
  otaHistoryURL: '/c/smt/ota-job-history',
  manageUserURL: '/c/smt/user-management',
  getUsersURL: '/c/smt/user-management',
  getUserScopeURL: '/c/smt-user/scope',
  blogURL: '/c/smt/blog',
  forgotPasswordURL: '/c/smt/forgotpassword',
  resetPasswordURL: '/c/smt/confirm-forgotpassword',
  repairHistoryURL: '/c/smt/repairdevice',
  uploadSchemaURL: '/c/cm/schema/upload',
  updateSchemaURL: '/c/cm/schema/update/configschema',
  fetchSKUListURL: '/c/cm/sku/list',
  fetchSchemaURL: '/c/cm/schema/getconfigschema',
  otaStatusURL: '/c/smt/ota/status',
  otaUpgradeURL: '/c/smt/ota/upgrade'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
