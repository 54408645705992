import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { QuillEditorComponent } from 'ngx-quill';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from 'src/app/core/services/data.service';
import { UtilService } from 'src/app/core/services/util.service';
import { ModalComponent } from '../modal/modal.component';
import { ModalBlogComponent } from '../modal-blog/modal-blog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  blogItem = {};
  blogList = [];
  bolgEmptyMsg = '';
  emptyMsg = '';
  userScope = '';
  form: FormGroup = this.fb.group({
    html: new FormControl(),
    blogTitle: new FormControl('', [Validators.required]),
  });

  public editorOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      // [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      // [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      // [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }], // dropdown with defaults from theme
      // [{ font: [] }],
      // [{ align: [] }],

      ['clean'], // remove formatting button

      ['link'], // link and image, video
      // ['link', 'image', 'video'], // link and image, video
    ],
  };

  constructor(
    private router: Router,
    public utilService: UtilService,
    public dataService: DataService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.utilService.checkIfLoggedIn();
    this.userScope = this.utilService.getUserScope();

    this.translate.stream('NEWS_UPDATES.EMPTY_MSG').subscribe((res) => {
      // console.log(res);
      this.emptyMsg = res;
    });

    if (this.utilService.getUserScope() === 'sm') {
      this.getBlogs(); // for admin
    } else {
      this.getAllBlogs(); // for user
    }
  }

  goToAdminDashboard() {
    this.router.navigate(['/add-news']);
  }

  isBase64(str) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  removeTags(str) {
    return str.replace(/(<([^>]+)>)/gi, '');
  }

  handleResponse(res) {
    this.bolgEmptyMsg = '';
    if (
      res &&
      res.hasOwnProperty('StatusCode') &&
      res.StatusCode === 200 &&
      res.hasOwnProperty('Result') &&
      res.Result.length > 0
    ) {
      this.blogList = res.Result;
      if (this.blogList && this.blogList.length > 0) {
        // decrypt the description and add editable property
        this.blogList.map((o) => {
          o.editable = false;
          o.description = this.isBase64(o.description)
            ? atob(o.description)
            : o.description;
          o.textDesc = this.removeTags(o.description);
          o.shortDesc =
            o.description.length > 350
              ? o.description.substr(0, 350) + '...'
              : o.description;
        });

        // sort in descending order
        // this.blogList = this.blogList.sort(
        //   (x, y) =>
        //     new Date(y.uuid * 1000).valueOf() - new Date(x.uuid * 1000).valueOf()
        // );

        this.blogList = this.blogList.sort((x, y) => {
          if (x.hasOwnProperty('ts') && y.hasOwnProperty('ts')) {
            return (
              new Date(y.ts * 1000).valueOf() - new Date(x.ts * 1000).valueOf()
            );
          } else if (x.hasOwnProperty('ts') && !y.hasOwnProperty('ts')) {
            return (
              new Date(y.uuid * 1000).valueOf() -
              new Date(x.ts * 1000).valueOf()
            );
          } else if (!x.hasOwnProperty('ts') && y.hasOwnProperty('ts')) {
            return (
              new Date(y.ts * 1000).valueOf() -
              new Date(x.uuid * 1000).valueOf()
            );
          } else {
            return (
              new Date(y.uuid * 1000).valueOf() -
              new Date(x.uuid * 1000).valueOf()
            );
          }
        });

        // based on admin or user call edit or display
        if (this.utilService.getUserScope() === 'sm') {
          // move inactive news to the end of the array
          const activeNews = this.blogList.filter((o) => o.dm === 0);
          const inactiveNews = this.blogList.filter((o) => o.dm === 1);

          // spread operator won't work here for some reason
          this.blogList = [].concat(activeNews, inactiveNews);
          this.displayBlog(this.blogList[0]);
        } else {
          this.displayBlog(this.blogList[0]);
        }
      } else {
        this.blogList = [];
        // this.bolgEmptyMsg =
        //   'Click on Add News to publish your first news item...';
        this.bolgEmptyMsg = `${this.emptyMsg}...`;
      }
    } else {
      const message =
        res.hasOwnProperty('Message') && res.Message
          ? res.Message
          : 'An unexpected error occured.';
      this.handleError(message);
    }
    this.spinner.hide();
  }

  // fetch all blogs
  // ! the date filters/arguments needs to be sent as well
  getAllBlogs() {
    this.spinner.show();

    this.dataService.getAllBlogs().subscribe(
      (res) => {
        this.handleResponse(res);
      },
      (err) => {
        const message =
          err.error.hasOwnProperty('Message') && err.error.Message
            ? err.error.Message
            : 'An unexpected error occured.';
        this.handleError(message);
        this.spinner.hide();
      }
    );
  }

  getBlogs() {
    this.spinner.show();
    const reqBody = {
      action: 'getall',
    };
    this.dataService.getBlogs(reqBody).subscribe(
      (res) => {
        this.handleResponse(res);
      },
      (err) => {
        const message =
          err.error.hasOwnProperty('Message') && err.error.Message
            ? err.error.Message
            : 'An unexpected error occured.';
        this.handleError(message);
        this.spinner.hide();
      }
    );
  }

  clickNewsItem(newsItem) {
    this.blogItem = { ...newsItem };
  }

  displayBlog(newsItem) {
    newsItem.description = this.isBase64(newsItem.description)
      ? atob(newsItem.description)
      : newsItem.description;
    this.clickNewsItem(newsItem);
  }

  // edit a blog
  editBlog(newsItem, mode) {
    // only if blog is active
    if (newsItem.dm === 0) {
      // un-edit other news items
      this.blogList.map((o) => (o.editable = false));

      newsItem.mode = mode;
      localStorage.setItem('editBlog', JSON.stringify(newsItem));
      this.router.navigate(['/add-news']);
    }
  }

  // cancel editing blog
  cancelEditBlog(newsItem) {
    // only if blog is active
    if (newsItem.dm === 0) {
      newsItem.editable = false;
      this.clickNewsItem(newsItem);
    }
  }

  // delete a blog
  deleteBlog(newsItem, dm) {
    this.spinner.show();

    const reqBody = {
      uuid: newsItem.uuid,
      action: 'toggle',
      uid: this.utilService.getUsername(),
      dm, // dm =0 Active & dm = 1 Inactive
    };

    this.dataService.blog(reqBody).subscribe(
      (res) => {
        if (res && res.hasOwnProperty('StatusCode') && res.StatusCode === 200) {
          const message = res.hasOwnProperty('Message')
            ? res.Message
            : 'Blog deleted successfully';

          this.openModalPopUp(message, 'fetch');
        } else {
          const message = res.hasOwnProperty('Message')
            ? res.Message
            : 'An unexpected error occurred.';
          this.handleError(message);
        }
        this.spinner.hide();
      },
      (err) => {
        this.handleError(err);
        this.spinner.hide();
      }
    );
  }

  // handle error
  handleError(error) {
    if (error) {
      this.openModalPopUp(error, '');
    }
  }

  // modal pop-ups for showing warning/error messaages
  openModalPopUp(bodyText, mode) {
    if (bodyText) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        message: bodyText,
      };
      if (mode === 'fetch') {
        this.dialog
          .open(ModalComponent, dialogConfig)
          .afterClosed()
          .subscribe((res1) => {
            this.getBlogs();
          });
      } else {
        this.dialog.open(ModalComponent, dialogConfig);
      }
    }
  }

  byPassHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  readMore(blog) {
    // console.log(blog);
    if (blog.hasOwnProperty('dm') && blog.dm === 0) {
      this.openBlogModalPopUp(blog);
    } else if (blog.hasOwnProperty('dm') && blog.dm === 1) {
    } else {
      this.openBlogModalPopUp(blog);
    }
  }

  openBlogModalPopUp(obj) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      blog: obj,
    };
    this.dialog.open(ModalBlogComponent, dialogConfig);
  }
}
