import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/services/data.service';
import { ExcelService } from 'src/app/core/services/excel.service';

import { Tooltip } from '../../tooltip.enum';

export interface OTAHistory {
  ota_job: string;
  timestamp: number;
  ofv: string;
  mfv: string;
  cfv: string;
  status: string;
}

@Component({
  selector: 'app-ota-history',
  templateUrl: './ota-history.component.html',
  styleUrls: ['./ota-history.component.scss'],
  providers: [DatePipe],
})
export class OtaHistoryComponent implements OnInit {
  @Input() deviceID: string;
  otaHistoryData: OTAHistory[] = [];
  otaHistoryDataCopy: OTAHistory[] = [];
  tooltip = '';
  data;

  constructor(
    public dataService: DataService,
    public excelService: ExcelService,
    public translate: TranslateService
  ) {
    this.translate.stream(['TOOLTIPS.ota']).subscribe((res) => {
      this.tooltip = res['TOOLTIPS.ota'];
    });
  }

  ngOnInit() {
    // this.tooltip = Tooltip.ota;
    this.fetchData();
  }

  async fetchData() {
    const otaHistoryPromise = this.getOTAHistoryData();
    const otaHistoryResult = await otaHistoryPromise;
    if (otaHistoryResult && otaHistoryResult.length > 0) {
      this.otaHistoryData = otaHistoryResult.slice();
      this.otaHistoryDataCopy = otaHistoryResult.slice();
    }
  }

  getOTAHistoryData() {
    const obj = {
      deviceId: this.deviceID,
    };
    return this.dataService
      .getOTAHistoryData(obj)
      .toPromise()
      .catch((e) => e);
  }

  // sort data
  sortData(sort: Sort) {
    this.data = this.otaHistoryDataCopy.slice();
    if (!sort.active || sort.direction === '') {
      this.otaHistoryData = this.data;
      return;
    }

    this.otaHistoryData = this.data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'ota_job':
          return compare(a.ota_job, b.ota_job, isAsc);
        case 'timestamp':
          return compare(a.timestamp, b.timestamp, isAsc);
        case 'ofv':
          return compare(a.ofv, b.ofv, isAsc);
        case 'mfv':
          return compare(a.mfv, b.mfv, isAsc);
        case 'cfv':
          return compare(a.cfv, b.cfv, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);

        default:
          return 0;
      }
    });
  }

  // export to excel functionality
  exportToExcel() {
    const excelarr = [];

    if (this.otaHistoryData.length > 0) {
      this.otaHistoryData.forEach((ele: any) => {
        const date = new Date(ele.timestamp);
        const formattedDate = new DatePipe('en-US').transform(
          date,
          'yyyy-MM-dd HH:mm:ss OOOO'
        );
        // const date = new Date(ele.timestamp).toString();

        excelarr.push({
          'OTA Job': ele.ota_job,
          'Date (MM/DD/YYY)': formattedDate,
          Status: ele.status,
        });
      });
      this.excelService.exportAsExcelFile(excelarr, 'OTA History');
    }
  }
}

// for sorting functionality
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
