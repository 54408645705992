import { Injectable } from '@angular/core';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { timeout, catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private loginUrl = environment.loginURL;
  constructor(private http: HttpClient) {}

  signIn(username: string, password: string): Observable<any> {
    let url = `${environment.baseURL}${environment.loginURL}`;
    const fullStr = username + ':' + password;
    const encStr = btoa(fullStr);
    const userData = {
      username,
      password,
    };
    const httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + encStr,
      }),
    };

    url =
      url +
      '?client_id=' +
      environment.loginClientId +
      '&client_secret=' +
      environment.loginClientSecret +
      '&scope=' +
      environment.loginScope +
      '&grant_type=' +
      environment.loginGrantType +
      '&audience=' +
      environment.loginAudience;

    return this.http.post(url, {}, httpOptions1);
  }

  forgotPassword(data): Observable<any> {
    if (environment.environmentName === 'static') {
      const url = environment.forgotPasswordURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.forgotPasswordURL}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }

  resetPassword(data): Observable<any> {
    if (environment.environmentName === 'static') {
      const url = environment.resetPasswordURL;
      return this.http.get(url);
    } else {
      const baseURL = environment.baseURL;
      const url = `${baseURL}${environment.resetPasswordURL}`;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };
      return this.http.post(url, data, httpOptions);
    }
  }
}
