import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';

export interface EventType {
  name: string;
  completed: boolean;
  subTypes?: EventType[];
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  eventType: EventType = {
    name: 'All',
    completed: true,
  };
  allComplete = true;

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {}

  ngOnInit() {
    this.eventType.subTypes = this.modalData.labels;
    this.updateAllComplete();
  }

  someComplete(): boolean {
    if (this.eventType.subTypes == null) {
      return false;
    }
    return (
      this.eventType.subTypes.filter((t) => t.completed).length > 0 &&
      !this.allComplete
    );
  }

  updateAllComplete() {
    this.allComplete =
      this.eventType.subTypes != null &&
      this.eventType.subTypes.every((t) => t.completed);
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.eventType.subTypes == null) {
      return;
    }
    this.eventType.subTypes.forEach((t) => (t.completed = completed));
  }

  onSubmit() {
    const eventType = this.eventType.subTypes
      .filter((t) => t.completed)
      .map((item) => item.name);
    this.dialogRef.close({ eventType });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
